import * as i0 from '@angular/core';
import { Injectable, Inject, NgModule } from '@angular/core';
import { DOCUMENT, CommonModule } from '@angular/common';
import { Subject } from 'rxjs';

/**
 * Service to interact with the `window` object (or its equivalent on a server platform).
 */
class WindowService {
  constructor(_doc) {
    this._doc = _doc;
  }
  get nativeWindow() {
    return this._doc?.defaultView || window;
  }
  static {
    this.ɵfac = function WindowService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || WindowService)(i0.ɵɵinject(DOCUMENT));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: WindowService,
      factory: WindowService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WindowService, [{
    type: Injectable
  }], function () {
    return [{
      type: Document,
      decorators: [{
        type: Inject,
        args: [DOCUMENT]
      }]
    }];
  }, null);
})();

/**
 * Cookie Consent configuration object.
 */
class NgcCookieConsentConfig {
  constructor() {
    /**
     * If false, this prevents the popup from showing (useful for giving to control to another piece of code)
     */
    this.enabled = true;
    /**
     * Simple whitelist for pages. specify page by:
     * - using a string : '/index.html'           (matches '/index.html' exactly) OR
     * - using RegExp   : /\/page_[\d]+\.html/    (matched '/page_1.html' and '/page_2.html' etc)
     *
     */
    this.whitelistPage = [];
    /**
     * Simple blacklist for pages. specify page by:
     * - using a string : '/index.html'           (matches '/index.html' exactly) OR
     * - using RegExp   : /\/page_[\d]+\.html/    (matched '/page_1.html' and '/page_2.html' etc)
     *
     */
    this.blacklistPage = [];
  }
  static {
    this.ɵfac = function NgcCookieConsentConfig_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgcCookieConsentConfig)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: NgcCookieConsentConfig,
      factory: NgcCookieConsentConfig.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgcCookieConsentConfig, [{
    type: Injectable
  }], null, null);
})();

/**
 * Service to interact with Cookie Consent API.
 */
class NgcCookieConsentService {
  constructor(windowService, config) {
    // Observable  sources
    this.popupOpenSource = new Subject();
    this.popupCloseSource = new Subject();
    this.initializingSource = new Subject();
    this.initializedSource = new Subject();
    this.initializationErrorSource = new Subject();
    this.statusChangeSource = new Subject();
    this.revokeChoiceSource = new Subject();
    this.noCookieLawSource = new Subject();
    // Observable  streams
    this.popupOpen$ = this.popupOpenSource.asObservable();
    this.popupClose$ = this.popupCloseSource.asObservable();
    this.initializing$ = this.initializingSource.asObservable();
    this.initialized$ = this.initializedSource.asObservable();
    this.initializationError$ = this.initializationErrorSource.asObservable();
    this.statusChange$ = this.statusChangeSource.asObservable();
    this.revokeChoice$ = this.revokeChoiceSource.asObservable();
    this.noCookieLaw$ = this.noCookieLawSource.asObservable();
    this.window = windowService.nativeWindow;
    this.init(config);
  }
  checkPopupInstantiated(method) {
    if (this.popupInstance == null) {
      throw new Error(`Cookie popup has not yet been instantiated. Cannot invoke ${method}()`);
    }
  }
  /**
   * Initializes Cookie Consent with the provided configuration.
   * @param config the configuration object
   */
  init(config) {
    if (this.window && this.window.cookieconsent) {
      // For Angular Universal suport
      this.cookieconsent = this.window.cookieconsent;
      this.config = config;
      // Set callbacks hooks:
      this.config.onPopupOpen = () => this.popupOpenSource.next();
      this.config.onPopupClose = () => this.popupCloseSource.next();
      this.config.onInitialise = status => this.initializingSource.next({
        status: status
      });
      this.config.onStatusChange = (status, chosenBefore) => {
        this.statusChangeSource.next({
          status: status,
          chosenBefore: chosenBefore
        });
      };
      this.config.onRevokeChoice = () => this.revokeChoiceSource.next();
      this.config.onNoCookieLaw = (countryCode, country) => {
        this.noCookieLawSource.next({
          countryCode: countryCode,
          country: country
        });
      };
      // Init the cookieconsent library with injected config
      this.cookieconsent.initialise(this.config, popup => {
        this.popupInstance = popup;
        this.initializedSource.next(); //notify of successful initialization
      }, (error, popup) => {
        this.initializationErrorSource.next({
          error: error
        }); //notify of failed initialization
      });
    }
  }
  /**
   * Gets the current configuration  used by the Cookie Consent.
   */
  getConfig() {
    return this.config;
  }
  /**
   * Gets the current cookie status.
   */
  getStatus() {
    return this.cookieconsent.status;
  }
  /**
   * Gets the current browser support for translations
   */
  getTransition() {
    return this.cookieconsent.hasTransition;
  }
  /**
   * Clears the current cookie status.
   */
  clearStatus() {
    this.checkPopupInstantiated('clearStatus');
    return this.popupInstance.clearStatus();
  }
  open() {
    this.checkPopupInstantiated('open');
    return this.popupInstance.open();
  }
  close(showRevoke) {
    this.checkPopupInstantiated('close');
    return this.popupInstance.close(showRevoke);
  }
  destroy() {
    this.checkPopupInstantiated('destroy');
    this.popupInstance.destroy();
  }
  fadeIn() {
    this.checkPopupInstantiated('fadeIn');
    this.popupInstance.fadeIn();
  }
  fadeOut() {
    this.checkPopupInstantiated('fadeOut');
    this.popupInstance.fadeOut();
  }
  isOpen() {
    this.checkPopupInstantiated('isOpen');
    return this.popupInstance.isOpen();
  }
  toggleRevokeButton(show) {
    this.checkPopupInstantiated('toggleRevokeButton');
    this.popupInstance.toggleRevokeButton(show);
  }
  hasAnswered() {
    this.checkPopupInstantiated('hasAnswered');
    return this.popupInstance.hasAnswered();
  }
  hasConsented() {
    this.checkPopupInstantiated('hasConsented');
    return this.popupInstance.hasConsented();
  }
  static {
    this.ɵfac = function NgcCookieConsentService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgcCookieConsentService)(i0.ɵɵinject(WindowService), i0.ɵɵinject(NgcCookieConsentConfig));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: NgcCookieConsentService,
      factory: NgcCookieConsentService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgcCookieConsentService, [{
    type: Injectable
  }], function () {
    return [{
      type: WindowService
    }, {
      type: NgcCookieConsentConfig
    }];
  }, null);
})();

/**
 * Provides the necessary dependencies to use the library in a Standalone Application
 */
function provideNgcCookieConsent(config) {
  return [[WindowService, {
    provide: NgcCookieConsentConfig,
    useValue: config
  }, NgcCookieConsentService]];
}

/**
 * Main module of the library
 */
class NgcCookieConsentModule {
  static forRoot(config) {
    return {
      ngModule: NgcCookieConsentModule,
      providers: provideNgcCookieConsent(config)
    };
  }
  static {
    this.ɵfac = function NgcCookieConsentModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgcCookieConsentModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgcCookieConsentModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgcCookieConsentModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule]
    }]
  }], null, null);
})();

/**
 * Palette Options
 *
 * Colours can be defined in additional stylesheets or using attributes.
 */
class NgcPaletteOptions {
  constructor() {
    this.popup = {
      background: '#000000',
      text: '#fff',
      link: '#fff'
    };
    this.button = {
      background: 'transparent',
      border: '#f8e71c',
      text: '#f8e71c'
    };
    this.highlight = {
      background: '#f8e71c',
      border: '#f8e71c',
      text: '#000000'
    };
  }
}

/* tslint:disable:max-line-length */
/**
 * Location Options
 *
 * The location services are disabled by default. You are encouraged to implement a handler to your own service, rather than using the free ones provided.
 * To enable the basic location services, set 'location' to 'true'. To add you own services or configure the order or execution, pass an object with configuration properties.
 */
class NgcLocationOptions {
  constructor() {
    /**
     * We can't react to errors from script tag resources, so we set a timeout. If we don't have the answer after 5000ms, try the next service.
     * Default: 5000 (milliseconds)
     */
    this.timeout = 5000;
    /**
     * This array defines the services that you want to use. We attempt to get the country code from the first service, and only if the service fails do we move onto the next service.
     * If all services fail, the popup is opened without modification.
     * If a service succeeds, then the two letter country code is passed to the 'Law' module, with changes your popup options depending on the cookie laws in the country code.
     * Default: ['freegeoip', 'ipinfo', 'maxmind']
     */
    this.services = ['freegeoip', 'ipinfo', 'maxmind'];
  }
}

/**
 * Law Optons.
 */
class NgcLawOptions {
  constructor() {
    // If false, then we only enable the popup if the country has the cookie law. We ignore all other country specific rules.
    // Default: true
    this.regionalLaw = true;
  }
}

/* tslint:disable:max-line-length */
/**
 * HTML Elements
 */
class NgcHTMLElements {
  constructor() {
    this.header = '<span class="cc-header">{{header}}</span>&nbsp;';
    this.message = '<span id="cookieconsent:desc" class="cc-message">{{message}}</span>';
    this.messagelink = '<span id="cookieconsent:desc" class="cc-message">{{message}} <a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="{{href}}" target="_blank" rel="noopener">{{link}}</a></span>';
    this.dismiss = '<a aria-label="dismiss cookie message" tabindex="0" class="cc-btn cc-dismiss">{{dismiss}}</a>';
    this.allow = '<a aria-label="allow cookies" tabindex="0" class="cc-btn cc-allow">{{allow}}</a>';
    this.deny = '<a aria-label="deny cookies" tabindex="0" class="cc-btn cc-deny">{{deny}}</a>';
    this.link = '<a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="{{href}}" target="_blank" rel="noopener">{{link}}</a>';
    this.close = '<span aria-label="dismiss cookie message" tabindex="0" class="cc-close">{{close}}</span>';
  }
}

/**
 * Cookie Options.
 *
 * It is recommended to set these values to correspond with your server.
 * You MUST set the ‘domain’ option, otherwise your cookies may not work.
 */
class NgcCookieOptions {
  constructor() {
    // Name of the cookie that keeps track of users choice
    // Default: 'cookieconsent_status'
    this.name = 'cookieconsent_status';
    // URL path that the cookie 'name' belongs to. The cookie can only be read at this location
    // Default: '/'
    this.path = '/';
    // The domain that the cookie 'name' belongs to. The cookie can only be read on this domain. Guide to cookie domains
    // Default: <empty string>
    this.domain = '';
    // The cookies expire date, specified in days (specify -1 for no expiry)
    // Default: 365
    this.expiryDays = 365;
    // If true the cookie will be created with the secure flag. Secure cookies will only be transmitted via HTTPS.
    this.secure = false;
  }
}

/**
 * Content Options.
 *
 * Text strings used for cookie consent window elements.
 */
class NgcContentOptions {
  constructor() {
    this.header = 'Cookies used on the website!';
    this.message = 'This website uses cookies to ensure you get the best experience on our website.';
    this.dismiss = 'Got it!';
    this.allow = 'Allow cookies';
    this.deny = 'Decline';
    this.link = 'Learn more';
    this.href = 'https://cookiesandyou.com';
    this.close = '&#x274c;';
    this.policy = 'Cookie Policy';
    this.target = '_blank';
  }
}

/**
 * Generated bundle index. Do not edit.
 */

export { NgcContentOptions, NgcCookieConsentConfig, NgcCookieConsentModule, NgcCookieConsentService, NgcCookieOptions, NgcHTMLElements, NgcLawOptions, NgcLocationOptions, NgcPaletteOptions, WindowService, provideNgcCookieConsent };
