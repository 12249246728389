// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  serverUrls: { 'dev.xkey.fi': 'https://xkey-backend-dev-afvpvcd5qa-lz.a.run.app/api/' },
  domain: 'https://dev.xkey.fi',
  useAnalytics: false,
  googleAnalyticsId: '',
  logo: 'https://xkey.fi/assets/xkey-clean-small.png',
};
